import React from "react";
import { Link } from "react-router-dom";
import { facebookIcon, instagramIcon, linkedIcon, twitterIcon } from "./SVG";

export default function Footer() {
  return (
    <div className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div className="footer__inner-row">
            <div className="footer__inner-col">
              <Link to="/" className="footer__inner-logo">
                <img
                  src={process.env.PUBLIC_URL + "/images/logo.svg"}
                  alt="logo"
                />
                CharityBlend
              </Link>
              <p>
              Empowering change through diversified giving. 
              Join us in making a difference, effortlessly.
              </p>
            </div>
            <div className="footer__inner-col">
              <h6 className="sm">Contacts</h6>
              <a
                href="mailto:info@Charityblend.org"
                className="footer__inner-link"
              >
                info@Charityblend.org
              </a>
              <p>Zurich, Switzerland</p>
            </div>
            <div className="footer__inner-col">
              <h6 className="sm">Socials</h6>
              <div className="footer__inner-socials">
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/CharityBlend/100095372537022/">{facebookIcon}</a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/charity.blend">{instagramIcon}</a>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/charityblendorg">{linkedIcon}</a>
              </div>
            </div>
          </div>
          <p className="center">
            Copyright © CharityBlend 2023. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}
