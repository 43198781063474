import React from "react";
import Hero from "./components/Hero";
import How from "./components/How";
import About from "./components/About/About";
import Interest from "./components/Interest";

export default function Landing() {
  return (
    <>
      <Hero />
      <How />
      <About />
      <Interest />
    </>
  );
}
