import React, { useState } from "react";
import { motion } from "framer-motion";
import Select from "../../Base/Select";
import { arrowRight } from "../../Base/SVG";
import { Link } from "react-router-dom";

const defaultState = {
  donorType: {
    id: 0,
    value: "Private Donor",
    link: "https://forms.gle/raN7joy8VwzLkKFM7"
  },
};
const donorType = [
  {
    id: 0,
    value: "Private Donor",
    link: "https://forms.gle/raN7joy8VwzLkKFM7"
  },
  {
    id: 1,
    value: "Business Donor",
    link: "https://forms.gle/Zi6LMhghK44qYhkp8"
  },
  {
    id: 2,
    value: "NGO Representative",
    link: "https://forms.gle/2YGGAZu2x9iR4wEx7"
  },
];
export default function Interest() {
  const [form, setForm] = useState(defaultState);

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const changeType = (item) => {
    updateForm({ donorType: item });
  };

  return (
    <div className="interest">
      <div className="auto__container">
        <div className="interest__inner">
          <div className="interest__content">
            <h2 className="big">Share your thoughts</h2>
            <p>
            Your feedback matters! Help us improve by filling out this quick survey. Let's build a better future together.
            </p>
          </div>
          <div className="interest__form">
            <div className="interest__form-image">
              <img
                src={process.env.PUBLIC_URL + "/images/formLine.svg"}
                alt="formLine"
              />
            </div>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="interest__form-inner"
              >
                <h5>I am a ..</h5>
                <Select
                  value={form.donorType}
                  onChange={changeType}
                  list={donorType}
                  selected={donorType[0]}
                />
                <div className="interest__form-footer">
                <Link target="_blank" to={form.donorType.link} className="button primary" style={{ fontSize: '17px', textDecoration: 'none', color: '#white', background: '#green', padding: '10px 20px', borderRadius: '5px' }}>
                  Go to survey {arrowRight}
                </Link>
                </div>
              </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
