import React from "react";

export default function Hero() {
  return (
    <div className="intro">
      <div className="auto__container">
        <div className="intro__inner">
          <div className="intro__inner-content">
            <span></span>
            <h1>Caring has never been easier</h1>
            <p className="big">
              Your recurring donations, diversified across the causes that matter to you.
            </p>
          </div>
          <div className="intro__inner-image">
            <img
              src={process.env.PUBLIC_URL + "/images/intro.png"}
              alt="intro"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
