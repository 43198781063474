import React from "react";
import { Link } from "react-router-dom";
import { arrowLeft, arrowRight } from "./SVG";

export default function Header({ showSurveyLink }) { // Add a prop for the toggle

  var url = showSurveyLink ? "/survey" : "/";
  var label = showSurveyLink ? "Go to survey" : "Go to home";
  var arrow = showSurveyLink ? arrowRight : arrowLeft;
  return (
    <div className="header">
      <div className="auto__container">
        <div className="header__inner" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Link to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" />
            CharityBlend
          </Link>

            <Link to={url} className="button primary" style={{ fontSize: '17px', textDecoration: 'none', color: '#white', background: '#green', padding: '10px 20px', borderRadius: '5px' }}>
              {showSurveyLink && (<>{label} {arrow}</>)}
              {!showSurveyLink && (<>{arrow} {label}</>)}
            </Link>

        </div>
      </div>
    </div>
  );
}
