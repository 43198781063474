import React from "react";
import { motion } from "framer-motion";

export default function Users() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
    >
      <div className="about__row">
        <div className="about__grid">
          <div className="about__grid-row">
            <div className="about__grid-image">
              <img
                src={process.env.PUBLIC_URL + "/images/user/1-1.png"}
                alt="how"
              />
            </div>
            <div className="about__grid-image">
              <img
                src={process.env.PUBLIC_URL + "/images/user/1-2.png"}
                alt="how"
              />
            </div>
            <div className="about__grid-image">
              <img
                src={process.env.PUBLIC_URL + "/images/user/1-3.png"}
                alt="how"
              />
            </div>
          </div>
        </div>
        <div className="about__content">
          <h3>
            Effortless <span>Giving</span>
          </h3>
          <p>
            No more researching and choosing between organizations.
            We streamline your donations according to your interests.
          </p>
          <ul className="list">
            <li>Simplify your giving process.</li>
            <li>Let us handle the distribution of donations.</li>
            <li>Spend less time researching organizations.</li>
          </ul>
        </div>
      </div>
      <div className="about__row">
        <div className="about__content">
          <h3>
            Diversified <span>Impact</span>
          </h3>
          <p>
            Maximize your philanthropy by spreading your donations
            across multiple causes that align with your values.
          </p>
          <ul className="list">
            <li>Your donation impacts multiple causes.</li>
            <li>Align your giving with your values.</li>
            <li>Maximize the effectiveness of your donations.</li>
          </ul>
        </div>
        <div className="about__image">
          <img src={process.env.PUBLIC_URL + "/images/user/2.png"} alt="how" />
        </div>
      </div>
      <div className="about__row">
        <div className="about__image sm">
          <img src={process.env.PUBLIC_URL + "/images/user/3.png"} alt="how" />
        </div>
        <div className="about__content big">
          <h3>
            Transparent <span>Tracking</span>
          </h3>
          <p>
            Stay informed about where your money goes and receive detailed reports about the distribution of your donations.
          </p>
          <ul className="list">
            <li>Know exactly where your money goes.</li>
            <li>Receive detailed distribution reports.</li>
            <li>Stay updated on new organizations matching your interests.</li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
}
