import React, { useState } from "react";
import { motion } from "framer-motion";
import Select from "../../Base/Select";
import { useForm } from "react-hook-form";

const defaultState = {
  name: "",
  donorType: {
    id: 0,
    value: "Private Donor",
  },
  email: "",
};
const donorType = [
  {
    id: 0,
    value: "Private Donor",
  },
  {
    id: 1,
    value: "Business Donor",
  },
  {
    id: 2,
    value: "NGO Representative",
  },
];
export default function Interest() {
  const [status, setStatus] = useState("form");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [form, setForm] = useState(defaultState);
  const [error, setError] = useState(false);

  const btnClick = () => {
    setStatus("loading")
    setError(false);
    fetch(process.env.REACT_APP_USER_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        name: form.name,
        email: form.email,
        type: form.donorType.id
      })
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        setStatus("success")
      })
      .catch((err) => {
        setStatus("error")
        console.log(err.message);
      });
    setTimeout(() => {
      setStatus("form");
      setForm(defaultState);
    }, 10000);

  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const changeType = (item) => {
    updateForm({ donorType: item });
  };
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  return (
    <div className="interest">
      <div className="auto__container">
        <div className="interest__inner">
          <div className="interest__content">
            <h2 className="big">Have we piqued your interest?</h2>
            <p>
              Join our community of changemakers. Whether you're a donor seeking to diversify impact,
              or an NGO aiming to connect with compassionate supporters, sign up to stay informed.
              Let's amplify change, together.
            </p>
          </div>
          <div className="interest__form">
            <div className="interest__form-image">
              <img
                src={process.env.PUBLIC_URL + "/images/formLine.svg"}
                alt="formLine"
              />
            </div>
            {status === "form" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="interest__form-inner"
              >
                <h5>Fill out the form to stay updated on our launch</h5>
                <div
                  className={
                    "input " + (form.name === "" && error ? "error" : "")
                  }
                >
                  <input
                    {...register("name", { required: true, minLength: 2, maxLength: 30 })}
                    type="text"
                    placeholder="Name"
                    value={form.name}
                    onChange={onChangeInput("name")}

                  />
                  {errors.name && (
                    <span className="error">Please enter your name</span>
                  )}
                </div>
                <Select
                  {...register("user_type")}
                  value={form.donorType}
                  onChange={changeType}
                  list={donorType}
                  selected={donorType[0]}
                />
                <div
                  className={
                    "input " + (form.email === "" && error ? "error" : "")
                  }
                >
                  <input
                    {...register("email", {
                      required: "required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "invalid email format"
                      }
                    })}
                    type="text"
                    placeholder="Email"
                    value={form.email}
                    onChange={onChangeInput("email")}
                  />
                  {errors.email && (
                    <span className="error">Please enter a valid email</span>
                  )}
                </div>
                <div className="interest__form-footer">
                  <button
                    type="button"
                    className="button submit"
                    onClick={handleSubmit(btnClick)}
                  >
                    Send
                  </button>
                </div>
              </motion.div>
            )}
            {status === "loading" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="status"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40vh", // Adjust this value to match your requirements
                }}
              >
                <div className="status__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/spinner.png"}
                    alt="Spinner"
                    style={{
                      width: "100px", // Adjust this value to change the size of the spinner
                      height: "100px", // Adjust this value to change the size of the spinner
                      animation: "spin 1s linear infinite", // Use CSS animation
                    }}
                  />
                </div>
              </motion.div>
            )}
            <style>{`
              /* CSS Animation */
              @keyframes spin {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
            `}</style>
            {status === "error" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="status"
              >
                <div className="status__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/formError.png"}
                    alt="image"
                  />
                </div>
                <h5 className="big">Something went wrong!</h5>
                <p>Try again in a few minutes</p>
              </motion.div>
            )}
            {status === "success" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="status"
              >
                <div className="status__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/formSuccess.png"}
                    alt="image"
                  />
                </div>
                <h5 className="big">Thank you!</h5>
                <p>You will receive a confirmation email shortly</p>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
