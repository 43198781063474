import React, { useRef, useState } from "react";
import User from "./Users";
import NGO from "./ NGO";
import { arrowLeft, arrowRight } from "../../../Base/SVG";

export default function About() {
  const [tab, setTab] = useState("users");
  const anchorRef = useRef(null);
  return (
    <div className="about">
      <div className="auto__container">
        <div className="about__inner">
          <div className="anchor" ref={anchorRef}></div>
          <h2>
            {tab === "users" && "For Donors"}
            {tab === "ngo" && "For NGOs"}
          </h2>
          {tab === "users" && <User />}
          {tab === "ngo" && <NGO />}
          <div className="about__inner-footer">
            {tab === "users" && (
              <button
                type="button"
                className="button primary"
                onClick={() => {
                  setTab("ngo");
                  anchorRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                For NGOs {arrowRight}
              </button>
            )}
            {tab === "ngo" && (
              <button
                type="button"
                className="button primary"
                onClick={() => {
                  setTab("users");
                  anchorRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {arrowLeft} For Donors
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
