export const arrowRight = (
  <svg viewBox="0 0 33 19" fill="none">
    <path
      d="M25.7171 10.526H7.2015C6.62546 10.526 6.17285 10.0734 6.17285 9.4974C6.17285 8.92135 6.62546 8.46875 7.2015 8.46875H25.7171C26.2932 8.46875 26.7458 8.92135 26.7458 9.4974C26.7458 10.0734 26.2932 10.526 25.7171 10.526Z"
      fill="currentColor"
    />
    <path
      d="M20.5731 17.7143C20.4382 17.716 20.3044 17.6894 20.1804 17.6362C20.0564 17.5831 19.9449 17.5046 19.853 17.4057C19.4416 16.9943 19.4416 16.3565 19.853 15.9451L26.3335 9.46458L19.853 2.98411C19.4416 2.57266 19.4416 1.9349 19.853 1.52344C20.2645 1.11198 20.9022 1.11198 21.3137 1.52344L28.5142 8.72396C28.9257 9.13542 28.9257 9.77318 28.5142 10.1846L21.3137 17.3852C21.108 17.5909 20.8405 17.6937 20.5937 17.6937L20.5731 17.7143Z"
      fill="currentColor"
    />
  </svg>
);
export const arrowLeft = (
  <svg viewBox="0 0 33 19" fill="none">
    <path
      d="M7.19938 8.47396L25.715 8.47396C26.291 8.47396 26.7437 8.92656 26.7437 9.5026C26.7437 10.0786 26.291 10.5312 25.715 10.5312L7.19938 10.5312C6.62334 10.5312 6.17074 10.0786 6.17074 9.5026C6.17074 8.92656 6.62334 8.47396 7.19938 8.47396Z"
      fill="currentColor"
    />
    <path
      d="M12.3434 1.28568C12.4783 1.28403 12.6121 1.31064 12.7361 1.36379C12.8601 1.41694 12.9716 1.49545 13.0635 1.59427C13.4749 2.00573 13.4749 2.64349 13.0635 3.05495L6.58301 9.53542L13.0635 16.0159C13.4749 16.4273 13.4749 17.0651 13.0635 17.4766C12.652 17.888 12.0143 17.888 11.6028 17.4766L4.40228 10.276C3.99082 9.86458 3.99082 9.22682 4.40228 8.81537L11.6028 1.61485C11.8085 1.40912 12.076 1.30625 12.3229 1.30625L12.3434 1.28568Z"
      fill="currentColor"
    />
  </svg>
);
export const facebookIcon = (
  <svg viewBox="0 0 24 25" fill="none">
    <path
      d="M17 4.18179C17 3.90565 16.7761 3.68179 16.5 3.68179H14C11.3793 3.55125 9.14528 5.56189 9 8.18179V10.8818H6.5C6.22386 10.8818 6 11.1057 6 11.3818V13.9818C6 14.2579 6.22386 14.4818 6.5 14.4818H9V21.1818C9 21.4579 9.22386 21.6818 9.5 21.6818H12.5C12.7761 21.6818 13 21.4579 13 21.1818V14.4818H15.62C15.8487 14.4851 16.0505 14.3327 16.11 14.1118L16.83 11.5118C16.8703 11.3621 16.8389 11.2021 16.7449 11.0788C16.6509 10.9554 16.5051 10.8827 16.35 10.8818H13V8.18179C13.0515 7.66889 13.4845 7.27921 14 7.28179H16.5C16.7761 7.28179 17 7.05794 17 6.78179V4.18179Z"
      fill="currentColor"
    />
  </svg>
);
export const instagramIcon = (
  <svg viewBox="0 0 25 25" fill="none">
    <path
      d="M9.97762 12.6836C9.97762 13.9107 10.9722 14.9057 12.1997 14.9057C13.4272 14.9057 14.4218 13.9111 14.4218 12.6836C14.4218 11.4565 13.4272 10.4615 12.1997 10.4615C10.9726 10.4615 9.97762 11.4561 9.97762 12.6836Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1997 7.21818C13.9797 7.21818 14.1905 7.22484 14.8935 7.25693C15.6522 7.29151 16.3564 7.44359 16.898 7.98526C17.4397 8.52693 17.5918 9.23109 17.6264 9.98984C17.6585 10.6928 17.6651 10.9036 17.6651 12.6836C17.6651 14.4636 17.6585 14.6744 17.6264 15.3773C17.5918 16.1361 17.4397 16.8403 16.898 17.3819C16.3564 17.9236 15.6522 18.0757 14.8935 18.1103C14.1905 18.1423 13.9797 18.149 12.1997 18.149C10.4197 18.149 10.2089 18.1423 9.50596 18.1103C8.74721 18.0757 8.04304 17.9236 7.50137 17.3819C6.95971 16.8403 6.80762 16.1361 6.77304 15.3773C6.74096 14.6744 6.73429 14.4636 6.73429 12.6836C6.73429 10.9036 6.74096 10.6928 6.77304 9.98984C6.80762 9.23109 6.95971 8.52693 7.50137 7.98526C8.04304 7.44359 8.74721 7.29151 9.50596 7.25693C10.2089 7.22484 10.4197 7.21818 12.1997 7.21818ZM8.77637 12.6836C8.77637 10.7932 10.3089 9.26026 12.1997 9.26026C14.0905 9.26026 15.623 10.7932 15.623 12.6836C15.623 14.574 14.0901 16.1069 12.1997 16.1069C10.3093 16.1069 8.77637 14.574 8.77637 12.6836ZM15.7585 9.92484C16.2003 9.92484 16.5585 9.56667 16.5585 9.12484C16.5585 8.68302 16.2003 8.32484 15.7585 8.32484C15.3166 8.32484 14.9585 8.68302 14.9585 9.12484C14.9585 9.56667 15.3166 9.92484 15.7585 9.92484Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07679 2.74359C9.14346 2.69526 9.48387 2.68359 12.1997 2.68359C14.9155 2.68359 15.256 2.69526 16.3226 2.74359C17.9485 2.81776 19.3755 3.21609 20.5214 4.36193C21.6676 5.50818 22.0655 6.93568 22.1397 8.56068C22.188 9.62734 22.1997 9.96776 22.1997 12.6836C22.1997 15.3994 22.188 15.7398 22.1397 16.8065C22.0655 18.4323 21.6672 19.8594 20.5214 21.0053C19.3751 22.1515 17.9472 22.5494 16.3226 22.6236C15.256 22.6719 14.9155 22.6836 12.1997 22.6836C9.48387 22.6836 9.14346 22.6719 8.07679 22.6236C6.45096 22.5494 5.02387 22.1511 3.87804 21.0053C2.73179 19.859 2.33387 18.4315 2.25971 16.8065C2.21137 15.7398 2.19971 15.3994 2.19971 12.6836C2.19971 9.96776 2.21137 9.62734 2.25971 8.56068C2.33387 6.93484 2.73221 5.50776 3.87804 4.36193C5.02429 3.21568 6.45179 2.81776 8.07679 2.74359ZM12.1997 6.01693C10.3893 6.01693 10.1622 6.02443 9.45096 6.05693C8.36762 6.10651 7.41596 6.37193 6.65179 7.13568C5.88804 7.89943 5.62262 8.85109 5.57304 9.93484C5.54054 10.6461 5.53304 10.8732 5.53304 12.6836C5.53304 14.494 5.54054 14.7211 5.57304 15.4323C5.62262 16.5157 5.88804 17.4673 6.65179 18.2315C7.41554 18.9953 8.36721 19.2607 9.45096 19.3103C10.1622 19.3428 10.3893 19.3503 12.1997 19.3503C14.0101 19.3503 14.2372 19.3428 14.9485 19.3103C16.0318 19.2607 16.9835 18.9953 17.7476 18.2315C18.5114 17.4678 18.7768 16.5161 18.8264 15.4323C18.8589 14.7211 18.8664 14.494 18.8664 12.6836C18.8664 10.8732 18.8589 10.6461 18.8264 9.93484C18.7768 8.85151 18.5114 7.89984 17.7476 7.13568C16.9839 6.37193 16.0322 6.10651 14.9485 6.05693C14.2372 6.02443 14.0101 6.01693 12.1997 6.01693Z"
      fill="currentColor"
    />
  </svg>
);
export const linkedIcon = (
  <svg viewBox="0 0 25 25" fill="none">
    <path
      d="M15.5499 9.08359C14.002 9.07828 12.5154 9.68879 11.4181 10.7805C10.3207 11.8723 9.70255 13.3556 9.6999 14.9036V20.7836C9.6999 21.0223 9.79472 21.2512 9.96351 21.42C10.1323 21.5888 10.3612 21.6836 10.5999 21.6836H12.6999C13.197 21.6836 13.5999 21.2806 13.5999 20.7836V14.9036C13.5995 14.3523 13.8337 13.8268 14.244 13.4585C14.6542 13.0902 15.2018 12.9139 15.7499 12.9736C16.7559 13.1003 17.5078 13.9597 17.4999 14.9736V20.7836C17.4999 21.2806 17.9028 21.6836 18.3999 21.6836H20.4999C20.997 21.6836 21.3999 21.2806 21.3999 20.7836V14.9036C21.3973 13.3556 20.7791 11.8723 19.6817 10.7805C18.5844 9.68879 17.0978 9.07828 15.5499 9.08359Z"
      fill="currentColor"
    />
    <path
      d="M3.3999 10.8836C3.3999 10.3865 3.80285 9.98359 4.2999 9.98359H6.9999C7.49696 9.98359 7.8999 10.3865 7.8999 10.8836V20.7836C7.8999 21.2806 7.49696 21.6836 6.9999 21.6836H4.2999C3.80285 21.6836 3.3999 21.2806 3.3999 20.7836V10.8836Z"
      fill="currentColor"
    />
    <path
      d="M7.8999 5.93359C7.8999 7.17623 6.89254 8.18359 5.6499 8.18359C4.40726 8.18359 3.3999 7.17623 3.3999 5.93359C3.3999 4.69095 4.40726 3.68359 5.6499 3.68359C6.89254 3.68359 7.8999 4.69095 7.8999 5.93359Z"
      fill="currentColor"
    />
  </svg>
);
export const twitterIcon = (
  <svg viewBox="0 0 25 25" fill="none">
    <path
      d="M8.68041 20.6805C11.6678 20.7804 14.5687 19.668 16.7233 17.5963C18.8779 15.5245 20.1032 12.6695 20.1204 9.68047C20.7981 8.84188 21.3012 7.8763 21.6004 6.84047C21.646 6.67329 21.5893 6.49491 21.4554 6.38483C21.3216 6.27475 21.1356 6.25347 20.9804 6.33047C20.2535 6.6804 19.3843 6.52749 18.8204 5.95047C18.1112 5.1738 17.1176 4.71743 16.0664 4.6854C15.0151 4.65337 13.9956 5.04841 13.2404 5.78047C12.1994 6.78866 11.7629 8.26874 12.0904 9.68047C8.7404 9.88047 6.44041 8.29047 4.60041 6.11047C4.48746 5.98255 4.30956 5.93354 4.14703 5.98554C3.98451 6.03755 3.86811 6.18074 3.85041 6.35047C3.13252 10.3326 4.96727 14.3412 8.45041 16.4005C7.31004 17.7083 5.70861 18.5251 3.98041 18.6805C3.79059 18.7119 3.64178 18.8606 3.61015 19.0504C3.57852 19.2402 3.67106 19.4291 3.84041 19.5205C5.34393 20.2718 6.99964 20.6687 8.68041 20.6805"
      fill="currentColor "
    />
  </svg>
);
export const triIcon = (
  <svg viewBox="0 0 16 15" fill="none">
    <path
      d="M15.5073 4.88561L9.26264 12.6299C8.67027 13.3384 7.75702 13.3384 7.18932 12.6299L0.944675 4.88561C0.352297 4.15271 0.648486 3.59082 1.56174 3.59082H14.8902C15.8282 3.59082 16.0997 4.15271 15.5073 4.88561Z"
      fill="currentColor"
    />
  </svg>
);
