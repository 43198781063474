import React, { useState } from "react";
import { motion } from "framer-motion";

export default function How() {
  const [step, setStep] = useState("step1");
  return (
    <div className="how">
      <div className="auto__container">
        <div className="how__inner">
          <h2>How it works</h2>
          <div className="how__inner-row">
            {step === "step1" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="how__inner-tab"
              >
                <div className="how__inner-image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/how/1-new.png"}
                    alt="how"
                  />
                </div>
              </motion.div>
            )}

            {step === "step2" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="how__inner-tab"
              >
                <div className="how__inner-image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/how/2-new.png"}
                    alt="how"
                  />
                </div>
              </motion.div>
            )}
            {step === "step3" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="how__inner-tab"
              >
                <div className="how__inner-image rB">
                  <img
                    src={process.env.PUBLIC_URL + "/images/how/3.png"}
                    alt="how"
                  />
                </div>
              </motion.div>
            )}
            {step === "step4" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                exit={{ opacity: 0, y: 10 }}
                className="how__inner-tab"
              >
                <div className="how__inner-image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/how/4-new.png"}
                    alt="how"
                  />
                </div>
              </motion.div>
            )}
            <div className="how__inner-steps">
              <div
                className={
                  "how__inner-step " + (step === "step1" ? "active" : "")
                }
                onClick={() => setStep("step1")}
              >
                <h6>Select your interests</h6>
                {step === "step1" && (
                  <motion.p
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    Select the causes or interests that resonate with you.
                    You can also deselect specific organizations if you prefer not to contribute to them.
                  </motion.p>
                )}
              </div>
              <div
                className={
                  "how__inner-step " + (step === "step2" ? "active" : "")
                }
                onClick={() => setStep("step2")}
              >
                <h6>Set Donation Amount and Frequency</h6>
                {step === "step2" && (
                  <motion.p
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    Decide how much and how often you want to donate. 
                    This could be a one-time donation, or a recurring contribution.
                  </motion.p>
                )}
              </div>
              <div
                className={
                  "how__inner-step " + (step === "step3" ? "active" : "")
                }
                onClick={() => setStep("step3")}
              >
                <h6>You have donated!</h6>
                {step === "step3" && (
                  <motion.p
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    Our platform will distribute your donations among the chosen causes, according to the preferences you set. 
                    You won't have to worry about figuring out where to donate.
                  </motion.p>
                )}
              </div>
              <div
                className={
                  "how__inner-step " + (step === "step4" ? "active" : "")
                }
                onClick={() => setStep("step4")}
              >
                <h6>Track your impact</h6>
                {step === "step4" && (
                  <motion.p
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    transition={{ duration: 0.4 }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    Receive detailed reports showing exactly which organizations received your donations and how much each one got. 
                  </motion.p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
