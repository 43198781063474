import React from "react";
import Landing from "./Landing/Landing";
import { Route, Routes } from "react-router-dom";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Survey from "./Survey/Survey";
import { useLocation } from "react-router-dom";

export default function App() {

  const location = useLocation(); // Use the useLocation hook
  const showSurveyLink = location.pathname === "/"; // Set true if the path is the home page

  return (
    <>
      <Header showSurveyLink={showSurveyLink}/>
      <div className="wrapper">
        <Routes>
          <Route path="" element={<Landing />} />
          <Route path="survey" element={<Survey/>} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}
