import React from "react";
import { motion } from "framer-motion";

export default function NGO() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
    >
      <div className="about__row">
        <div className="about__grid">
          <div className="about__grid-row">
            <div className="about__grid-image">
              <img
                src={process.env.PUBLIC_URL + "/images/ngo/1-1.png"}
                alt="how"
              />
            </div>
            <div className="about__grid-image">
              <img
                src={process.env.PUBLIC_URL + "/images/ngo/1-2.png"}
                alt="how"
              />
            </div>
            <div className="about__grid-image">
              <img
                src={process.env.PUBLIC_URL + "/images/ngo/1-3.png"}
                alt="how"
              />
            </div>
          </div>
        </div>
        <div className="about__content">
          <h3>
            Increased <span>Visibility</span>
          </h3>
          <p>
            Gain exposure to a diverse donor base interested in your cause,
            enhancing your reach and fundraising potential.
          </p>
          <ul className="list">
            <li>Reach a larger, diverse donor base.</li>
            <li>Enhance your fundraising potential.</li>
            <li>Gain exposure to donors genuinely interested in your cause.</li>
          </ul>
        </div>
      </div>
      <div className="about__row">
        <div className="about__content">
          <h3>
            Donor <span>Relationships</span>
          </h3>
          <p>
            Use our platform's tools to manage relationships with donors, track donation history, and maintain communication.
          </p>
          <ul className="list">
            <li>Manage relationships with donors via our platform.</li>
            <li>Track donation history effectively.</li>
            <li>Maintain smooth and regular communication with donors.</li>
          </ul>
        </div>
        <div className="about__image">
          <img src={process.env.PUBLIC_URL + "/images/ngo/2.png"} alt="how" />
        </div>
      </div>
      <div className="about__row">
        <div className="about__image sm">
          <img src={process.env.PUBLIC_URL + "/images/ngo/3.png"} alt="how" />
        </div>
        <div className="about__content big">
          <h3>
            Transparent <span>Reporting</span>
          </h3>
          <p>
            Gain insight into donor preferences and understand why donors may deselect your organization, aiding in your strategic planning.
          </p>
          <ul className="list">
            <li>Understand donor preferences.</li>
            <li>Learn why donors may deselect your organization.</li>
            <li>Use these insights for strategic planning and development.</li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
}
